// @import 'compass/css3';
@import '~compass-mixins/lib/compass/css3';

// $l: sqrt(2)*2px;

@mixin border-ring($c, $b, $g: $b) {
	$n: length($c);
	$r: .5*$b;
	$ba: 360deg/$n;
	$sl: ();
	$gl: ();
	
	@for $i from 1 through $n {
		$ca: $i*$ba;
		$sl: $sl, nth($c, $i) 0% $ca;
		
		// the default start for this is at 3 o'clock, 
		// and we want to bring it back to 12 o'clock
		// like the conic-gradient => subtract 90deg
		$ca: $ca - 90deg;
		$x: calc(50% + (50% - #{$r})*#{cos($ca)});
		$y: calc(50% + (50% - #{$r})*#{sin($ca)});
		$gl: $gl, 
			radial-Gradient(circle at $x $y, 
					nth($c, $i) calc(#{$r} - 1px), transparent $r)
	}
	
	border: solid $b transparent;
	padding: $g;
	border-radius: 50%;
	background: $gl, 
		conic-Gradient($sl);
	background-origin: border-box;
	--mask: 
			radial-Gradient(closest-side, 
				red calc(100% - #{$b} - #{$g} - 1px), 
				transparent calc(100% - #{$b} - #{$g}) calc(100% - #{$b}), 
				red  calc(100% - #{$b} + 1px) calc(100% - 1px), 
				transparent);
	-webkit-mask: var(--mask);
					mask: var(--mask);
}

.img-avatar {
	place-self: center;
	// width: Min(20em, 90vw);
	@include border-ring(#4285F4 #0F9D58 #F4B400 #DB4437, 1em, 0.25em)
}